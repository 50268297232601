<template>
  <CContainer class="mt-5">
    <CRow class="justify-content-center">
      <CCol
        col="12"
        lg="7"
      >
        <CCard class="p-4">
          <CCardBody>
            <CForm @submit.prevent="login">
              <h1 class="text-center">
                Онлайн-кассир
              </h1>
              <p class="text-center text-muted">
                Войдите в личный кабинет
              </p>
              <CAlert
                v-for="error in loginForm.errors.global"
                :key="error"
                color="danger"
                close-button
              >
                {{ error }}
              </CAlert>
              <CInput
                v-model="loginForm.data.email"
                placeholder="E-mail"
                required
                type="email"
              >
                <template #prepend-content>
                  <CIcon name="cilUser" />
                </template>
              </CInput>
              <CInput
                v-model="loginForm.data.password"
                placeholder="Пароль"
                required
                type="password"
              >
                <template #prepend-content>
                  <CIcon name="cilLockLocked" />
                </template>
              </CInput>
              <CRow align-vertical="center">
                <template v-if="loginForm.inProcessing">
                  <CCol>
                    <CButton
                      color="primary"
                      disabled
                      type="submit"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span class="sr-only">Подождите...</span>
                    </CButton>
                  </CCol>
                </template>
                <template v-else>
                  <CCol col="auto">
                    <CButton
                      class="btn-primary--green"
                      color="primary"
                      type="submit"
                    >
                      Войти
                    </CButton>
                  </CCol>
                </template>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import AuthService from '../auth';

export default {
  name: 'Login',

  data() {
    return {
      loginForm: {
        data: {
          email: null,
          password: null,
        },
        errors: {
          data: {},
          global: [],
        },
        inProcessing: false,
      },
    };
  },

  methods: {
    async login() {
      this.loginForm.errors.global = [];

      this.loginForm.inProcessing = true;

      try {
        await AuthService.loginByEmailAndPassword(this.loginForm.data);

        // await this.$router.push({ name: this.$store.getters.isServiceAdmin ? 'admin.reestrs.list' : 'admin.index' });
        await this.$router.push({ name: this.$store.getters.isServiceAdmin ? 'admin.reestrs.list' : 'admin.reestrs.list' });
      } catch (e) {
        if (e.response) {
          if (e.response.status === 403) {
            this.loginForm.errors.global.push('Аккаунт заблокирован');
          } else if (e.response.status === 404) {
            this.loginForm.errors.global.push('Неверный E-mail или пароль');
          }
        }
      } finally {
        this.loginForm.inProcessing = false;
      }
    },
  },
};
</script>
